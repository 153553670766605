import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/practitioner/appointment/calendar'
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/signin',
    name: 'SignIn',
    // route level code-splitting
    // this generates a separate chunk (signin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signin" */ '../views/SignIn.vue'),
  },
  {
    path: '/practitioner/appointment/calendar',
    name: "PractitionerAppointmentCalendar",
    component: () => import(/* webpackChunkName: "practitioner-calendar" */'../views/statistics/practitioner-appointment-calendar'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/practitioner/appointment/summary',
    name: "PractitionerAppointmentSummary",
    component: () => import(/* webpackChunkName: "practitioner-appointment-summary" */'../views/statistics/practitioner-appointment-summary'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/booking/summary',
    name: "BookingSummary",
    component: () => import(/* webpackChunkName: "booking-summary" */'../views/statistics/booking-summary'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/booking/availability',
    redirect: '/booking/availability/state'
  },
  {
    path: '/booking/availability/state',
    name: "BookingAvailabilityByState",
    component: () => import(/* webpackChunkName: "booking-availability-by-state" */'../views/statistics/booking-availability-by-state'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/booking/availability/provider',
    name: "BookingAvailabilityByProvider",
    component: () => import(/* webpackChunkName: "booking-availability-by-provider" */'../views/statistics/booking-availability-by-provider'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.authorized) {
      return next({
        path: '/signin',
        query: { redirect: to.fullPath }
      })
    }
  }
  next()
})


export default router
