import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker';
import router from './router'
import store from './store'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI)
import 'tippy.js/dist/tippy.css'; // optional for styling
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import ECharts from 'vue-echarts'
import { use as useChart } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components'

Vue.use(Antd)

useChart([
  CanvasRenderer,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent
])

// 全局注册组件
// https://github.com/ecomfe/vue-echarts/blob/HEAD/README.zh-Hans.md
Vue.component('v-chart', ECharts)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
